import Form from '@/components/form';
import FormTextField from '@/components/form/fields/textField';
import {
	Category,
	Item,
	MutationCategoryWriteArgs,
	MutationItemWriteArgs,
	QueryCategoriesReadArgs,
	QueryItemReadArgs,
	Uom,
	UomValidator,
} from '@/types/schema';
import { gql } from '@apollo/client';
import { Grid, Stack, Typography } from '@mui/material';
import { isEmpty, isNumber } from 'lodash-es';
import { ComponentType, Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { mutateGraphQL, queryGraphQL } from '../../../../../data';
import { CategoryWrite } from '../../../../../data/management/category.graphql';
import { ItemRead, ItemWrite } from '../../../../../data/management/item.graphql';
import idPick from '../../../../../helpers/idPick';
import { DeepPartial } from '../../../../../types/deepPartial';

export default function VendorMenuForm( { uom, onSubmit, Wrapper = Fragment, isPurchase }: {
	uom?: DeepPartial<Uom>,
	onSubmit: ( uom: Uom ) => void,
	isPurchase?: boolean,
	Wrapper: ComponentType<{ name: string, secondaryTitle: string, children: ReactNode }>
} ) {
	const { t } = useTranslation();
	const validationSchema = yup.object().shape( {
		name : yup
			.string()
			.required( t( 'common:uom-name' ) )
			.max( 64, t( 'common:uom-long' ) ),
		cost : yup
			.number()
			.required( 'Enter Cost' )
			.lessThan( 1000000000, t( 'common:cost-exceeded' ) ),
		price: yup
			.number()
			.required( t( 'common:enter-price' ) )
			.lessThan( 1000000000, 'Price Exceeded' ),
		item : yup.object().shape( {
			name: yup.string()
				.required( t( 'common:enter-item-name' ) )
				.max( 125, t( 'common:item-name-long' ) ),
		} ),
	} );
	
	return (
		<Form
			initialValues={{
				id      : uom?.id || null,
				sku     : uom?.sku || '',
				name    : uom?.name || '',
				cost    : uom?.cost || 0,
				price   : uom?.price || 0,
				quantity: isNumber( uom?.quantity ) ? uom.quantity : 0,
				item    : {
					id         : uom?.item?.id || null,
					name       : uom?.item?.name || '',
					description: uom?.item?.description || '',
					categories : [ { name: uom?.item?.categories?.[ 0 ]?.name || null } ],
				},
			} as Uom}
			validationSchema={validationSchema}
			onSubmit={async ( values: Partial<Uom> ) => {
				let foundCategory: Pick<Category, 'id'>;
				let itemCategories: Pick<Category, 'id'>[];
				if ( values.item?.categories?.[ 0 ]?.name ) {
					const { categoriesRead }: {
						categoriesRead: { items: Pick<Category, 'id' | 'name'>[] }
					} = await queryGraphQL<QueryCategoriesReadArgs>( {
						query    : gql`
							query CategoriesRead_e3a4($options: FilterOptions) {
								categoriesRead(options: $options) {
									items {
										id
										name
									}
								}
							}
						`,
						variables: {
							options: { limit: 1, filter: { name: values.item.categories[ 0 ]?.name } },
						},
					} );
					foundCategory = categoriesRead.items?.[ 0 ];
					if ( !categoriesRead.items?.[ 0 ] ) {
						const { categoryWrite } = await mutateGraphQL<MutationCategoryWriteArgs>( {
							mutation : CategoryWrite,
							variables: {
								input: { name: values.item.categories[ 0 ].name },
							},
						} );
						foundCategory = categoryWrite;
					}
				}
				
				if ( !isEmpty( uom?.item?.categories ) ) {
					itemCategories = [ ...uom?.item?.categories?.filter( ( cat ) => cat?.id !== foundCategory?.id ) || [],
					                   foundCategory! ].filter( Boolean ) as Pick<Category, 'id'>[];
				} else {
					itemCategories = [ foundCategory! ].filter( Boolean );
				}
				
				let item: Item;
				if ( uom?.item?.id ) {
					const { itemRead } = await queryGraphQL<QueryItemReadArgs>( {
						query    : ItemRead,
						variables: { id: uom.item.id },
					} );
					item = itemRead;
				}
				
				const { itemWrite } = await mutateGraphQL<MutationItemWriteArgs>( {
					mutation : ItemWrite,
					variables: {
						id    : values.item?.id || undefined,
						method: values.item?.id ? 'Menu Item Created' : 'Saved Changes',
						input : {
							name       : values.item?.name || null,
							description: values.item?.description || null,
							uoms       : [ ...!isEmpty( item!.uoms )
								? item!.uoms
									?.filter( ( uom ) => uom.id !== values.id )
									.map( ( uom ) => ( {
										...idPick( uom, [ 'sku', 'name', 'cost', 'quantity', 'selected', 'price' ] ),
									} ) )
								: [], {
								...idPick( values, [ 'sku', 'name', 'cost', 'quantity', 'selected', 'price' ] ),
							} ] as UomValidator[],
							categories : itemCategories?.map( ( { id } ) => id ).filter( Boolean ) || [],
						},
						
					},
				} );
				
				onSubmit( {
					...itemWrite.uoms?.find( ( uom ) => uom.id === values.id ) || itemWrite.uoms?.find( ( uom ) => uom.selected ) || {},
					item: {
						id         : itemWrite.id,
						name       : itemWrite.name,
						description: itemWrite.description,
						externalId : itemWrite.externalId,
					},
				} as Uom );
				
			}}>
			{( formik ) => {
				console.log( formik.values );
				return (
					<Wrapper
						name={uom?.item?.name ? uom.item.name : t( 'common:item' )}
						secondaryTitle={t( 'common:enter-vendor-item' )}>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								{uom && (
									<Stack direction='row'>
										<Typography ml={.5} sx={{ fontWeight: 800, alignSelf: 'center' }}>
											{uom?.quantity || 0}
										</Typography>
										<Typography ml={.5} sx={{ alignSelf: 'center' }}>
											in stock
										</Typography>
									</Stack>
								
								)}
							</Grid>
							<Grid item xs={12}>
								{!uom?.item?.name && (
									<FormTextField
										fullWidth
										required
										name='item.name'
										label={t( 'common:item-name' )}
									/>
								)}
							</Grid>
							{!isPurchase && (
								<Grid item xs={12}>
									<FormTextField
										fullWidth
										multiline
										name='item.description'
										label={t( 'common:description' )}
										rows={2}
									/>
								</Grid>
							)}
							{!isPurchase && (
								<Grid item xs={12} md={4}>
									<FormTextField
										fullWidth
										name='sku'
										label={t( 'common:sku' )}
									/>
								</Grid>
							)}
							{!isPurchase && (
								<Grid item xs={12} md={8}>
									<FormTextField
										fullWidth
										name='item.categories[0].name'
										label={t( 'common:category' )}
									/>
								</Grid>
							)}
							<Grid item xs={12} md={6}>
								<FormTextField
									fullWidth
									includeNegative
									name='quantity'
									type='number'
									label='Available'
									format={( value ) => +value}
									onFocus={( e ) => e.target.select()}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<FormTextField
									fullWidth
									name='name'
									label={t( 'common:unit' )}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<FormTextField
									fullWidth
									name='cost'
									type='number'
									label={t( 'common:cost' )}
									InputProps={{ inputProps: { min: 0 } }}
									format={( value ) => Math.abs( +value ) || 0}
									onFocus={( e ) => e.target.select()}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<FormTextField
									fullWidth
									name='price'
									type='number'
									label={t( 'common:price' )}
									InputProps={{ inputProps: { min: 0 } }}
									format={( value ) => Math.abs( +value ) || 0}
									onFocus={( e ) => e.target.select()}
								/>
							</Grid>
						</Grid>
					</Wrapper>
				);
			}}
		
		</Form>
	);
}
